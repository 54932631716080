/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import at from 'lodash/at';
import BasePage from 'pages/BasePage';
import ValidationInput from 'sf/components/ValidationInput';
import contact from 'models/contact';
import Button from 'components/Button';
import { asset } from 'helpers';
import PrivacyPolicy from 'components/PrivacyPolicy';

export default class WhitepaperForm extends BasePage {
  className = 'ts-Whitepaper';

  // props required by contact model
  static defaultProps = {
    onDone: () => { },
    source: 'Truststamp.ai Whitepaper Form',
    recipient: 'sales',
  };

  state = {};

  componentDidMount() {
    this.syncStateWithModel(contact, [
      'email',
      'first_name',
      'last_name',
      'company_name',
      'website_url',
      'consent',
    ]);
  }

  // form submission
  handleSubmit = (e) => {
    e.preventDefault();
    Button.setProcessing(this.submitButton);
    const hideProcessing = () => {
      Button.setProcessing(this.submitButton, false);
    };

    this.formValidation(contact, 'form.')
      .then(() => {
        const done = () => {
          this.props.onDone();
          contact.clear();
        };
        const fail = (err = {}) => {
          if (Object.keys(err.validation || {}).length) {
            this.formValidation(Promise.reject(err.validation));
          }
          console.log(err); // eslint-disable-line no-console
        };

        contact
          .sendExtendedMessage(this.props.recipient, {
            includeDebugInfo: false,
            captchaResponse: false,
            source: this.props.source,
          })
          .then(done, fail);
      })
      .finally(hideProcessing);
  };

  render() {
    // checking if all required fields are entered so that the submit button is enabled
    const isFormValid = at(this.state, [
      'first_name',
      'last_name',
      'email',
      'privacy',
    ])
      .every(Boolean);

    return (
      <div className={ this.cn`__form-wrapper` }>

        { /* title */ }
        <h5>Please fill the below form to download your free whitepaper:</h5>

        <form onSubmit={ this.handleSubmit }>

          { /* // First name field */ }
          <ValidationInput
            className={ this.cn`__input` }
            type="text"
            placeholder="First Name *"
            ref="form.first_name"
            stateLink={ [this, 'first_name'] }
          />

          { /* Last Name */ }
          <ValidationInput
            className={ this.cn`__input` }
            type="text"
            placeholder="Last Name *"
            ref="form.last_name"
            stateLink={ [this, 'last_name'] }
          />

          { /* Company Name */ }
          <ValidationInput
            className={ this.cn`__input` }
            type="text"
            placeholder="Company Name"
            ref="form.company_name"
            stateLink={ [this, 'company_name'] }
          />

          { /* Website URL */ }
          <ValidationInput
            className={ this.cn`__input` }
            type="text"
            placeholder="Website URL"
            ref="form.website_url"
            stateLink={ [this, 'website_url'] }
          />

          { /* // email field */ }
          <ValidationInput
            className={ this.cn`__input` }
            type="text"
            placeholder="E-mail *"
            ref="form.email"
            stateLink={ [this, 'email'] }
          />
          <div className="clearfix"></div>

          { /* // consent checkbox */ }
          <div className={ this.cn`__checkbox-container` }>
            <ValidationInput
              className={ this.cn`__checkbox` }
              type="checkbox"
              ref="form.consent"
              stateLink={ [this, 'consent'] }
            />
            <span className={ this.cn`__checkbox-text` }>
              I would like to receive emails from Trust Stamp
            </span>
          </div>
          <div className="clearfix"></div>

          { /* // privacy checkbox */ }
          <div className={ this.cn`__checkbox-container` }>
            <ValidationInput
              className={ this.cn`__checkbox` }
              type="checkbox"
              ref="form.privacy"
              stateLink={ [this, 'privacy'] }
            />
            <span className={ this.cn`__checkbox-text` }>I agree to the <PrivacyPolicy /></span>
          </div>
          <div className="clearfix"></div>

          { /* send button */ }
          <Button
            className={ this.cn`__button` }
            disabled={ !isFormValid }
            type="submit"
          >
            <span>Download</span>
            <img
              alt="Download"
              className={ this.cn`__button-icon` }
              src={ asset`ai/arrow.svg` }
            />
          </Button>
        </form>

      </div>
    );
  }
}
