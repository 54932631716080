/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import at from 'lodash/at';
import BasePage from 'pages/BasePage';
import ValidationInput from 'sf/components/ValidationInput';
import contact from 'models/contact';
import ValidationTextArea from 'components/ValidationTextarea';
import Button from 'components/Button';
import { asset } from 'helpers';
import PrivacyPolicy from 'components/PrivacyPolicy';

export default class CCPAForm extends BasePage {
  className = 'ts-CCPAPrivacyRequest';

  // props required by contact model
  static defaultProps = {
    onDone: () => { },
    source: 'Truststamp.ai CCPA Privacy Form',
    recipient: 'privacy',
  };

  state = {};

  componentDidMount() {
    this.syncStateWithModel(contact, [
      'email',
      'first_name',
      'message',
    ]);
  }

  // form submission
  handleSubmit = (e) => {
    e.preventDefault();
    Button.setProcessing(this.submitButton);
    const hideProcessing = () => {
      Button.setProcessing(this.submitButton, false);
    };

    this.formValidation(contact, 'form.')
      .then(() => {
        const done = () => {
          this.props.onDone();
          contact.clear();
        };
        const fail = (err = {}) => {
          if (Object.keys(err.validation || {}).length) {
            this.formValidation(Promise.reject(err.validation));
          }
          console.log(err); // eslint-disable-line no-console
        };

        contact
          .sendExtendedMessage(this.props.recipient, {
            includeDebugInfo: false,
            captchaResponse: false,
            source: this.props.source,
          })
          .then(done, fail);
      })
      .finally(hideProcessing);
  };

  render() {
    // checking if all fields are enter so that the submit button is enabled
    const isFormValid = at(this.state, [
      'first_name',
      'email',
      'state',
      'message',
      'privacy',
    ])
      .every(Boolean);

    return (
      <section className={ this.cn`__form-section` }>
        <div className={ this.cn`ts-container` }>
          <div className={ this.cn`__form-wrapper` }>
            <h5>California Residents CCPA Privacy Request</h5>

            <p>Please review our <PrivacyPolicy /> for more information.</p>

            <form onSubmit={ this.handleSubmit } className={ this.cn`__form` }>

              { /* // Requestor name field */ }
              <ValidationInput
                className={ this.cn`__input` }
                type="text"
                placeholder="Requestor Name *"
                ref="form.first_name"
                stateLink={ [this, 'first_name'] }
              />

              { /* // email field */ }
              <ValidationInput
                className={ this.cn`__input` }
                type="text"
                placeholder="E-mail *"
                ref="form.email"
                stateLink={ [this, 'email'] }
              />

              { /* // request details */ }
              <ValidationTextArea
                className={ this.cn`__textarea` }
                rows={ 3 }
                placeholder="Request Details *"
                ref="form.message"
                stateLink={ [this, 'message'] }
              />
              <div className="clearfix"></div>

              { /* // state checkbox */ }
              <ValidationInput
                className={ this.cn`__checkbox` }
                type="checkbox"
                ref="form.state"
                stateLink={ [this, 'state'] }
              />
              <span className={ this.cn`__checkbox-text` }>I am a resident of California</span>
              <div className="clearfix"></div>

              { /* // privacy checkbox */ }
              <ValidationInput
                className={ this.cn`__checkbox` }
                type="checkbox"
                ref="form.privacy"
                stateLink={ [this, 'privacy'] }
              />
              <span className={ this.cn`__checkbox-text` }>I agree to <PrivacyPolicy /></span>
              <div className="clearfix"></div>

              { /* send button */ }
              <Button
                className={ this.cn`__button` }
                disabled={ !isFormValid }
                type="submit"
              >
                <span>Send</span>
                <img
                  alt="Send"
                  className={ this.cn`__button-icon` }
                  src={ asset`ai/arrow.svg` }
                />
              </Button>
            </form>

          </div>
        </div>
      </section>
    );
  }
}
