/* global YEAR_CONF */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import BaseComponent from 'components/BaseComponent';
import PrivacyPolicy from 'components/PrivacyPolicy';
import { ROUTES } from 'constants';
import Icon from 'sf/components/Icon';
import { asset } from 'sf/helpers';
import { LINKS } from './constant';

export default class PageFooter extends BaseComponent {
  className = 'ts-PageFooter';

  static propTypes = {
    theme: PropTypes.string,
  };

  componentDidMount() {
    // script for linkedin insight tracking
    const _linkedin_partner_id = '1156714';
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);

    (function () {
      const b = document.createElement('script');
      b.type = 'text/javascript';
      b.async = true;
      b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
      document.body.appendChild(b);
    }());
  }

  render() {
    // social media icons
    const socialData = [
      {
        href: 'https://www.linkedin.com/company/10373549?trk=tyah&trkInfo=clickedVertical:company,clickedEntityId:10373549,idx:3-1-3,tarId:1466175435680,tas:trust%20stamp',
        type: 'linkedin-square',
      },
      {
        href: 'https://twitter.com/truststamp',
        type: 'twitter',
      },
      {
        href: 'https://www.facebook.com/Trust-Stamp-1751960355032233/',
        type: 'facebook-square',
      },
    ];
    const renderSocials = (social, index) => (
      <li
        key={ index }
      >
        <Link
          href={ social.href }
          rel="noopener noreferrer"
          target="_blank"
        >
          <Icon
            set="fa"
            size={ 15 }
            color="#fff"
            type={ social.type }
          />
        </Link>
      </li>
    );

    // footer links
    const renderLinks = ({ href, link, open }) => (
      <li key={ link }>
        { open ? (<Link rel="noopener noreferrer" target="_blank" to={ href }>{ link }</Link>)
        : (<Link to={ href }>{ link }</Link>)
        }
      </li>
    );

    return (
      <footer className={ this.rootcn() }>
        <div className="ts-container">
          <Link to="/">
            <img src={ asset`/img/header-logo--dark.svg` } alt="Trust Stamp" width="200" />
          </Link>
        </div>
        <hr />

        <div className="ts-container">
          <div className={ this.cn`__links` }>

            { /* links */ }
            { LINKS.map(({ id, title, list }) => (
              <div className={ this.cn`__column--single` } key={ id }>
                <h5>{ title }</h5>
                <ul>
                  { list.map(renderLinks) }
                </ul>
              </div>
            )) }

            { /* <div className={ this.cn`__column--double` }>
              <Link className={ this.cn`__anchor` } id="newsletter">Newsletter</Link>
              <h5>Newsletter</h5>
              <div className={ this.cn`__newsletter` }>
                <input type="email" placeholder="Enter email address" />
                <button>Join Now</button>
              </div>
              <ul>
                { socialData.map(renderSocials) }
              </ul>
            </div> */ }

          </div>
        </div>
        <hr />

        <div className="ts-container">
          <div className={ this.cn`__copyright` }>
            { /* copyright */ }
            <p>
              &copy;
              { `Trust Stamp ${YEAR_CONF}.
              Various aspects of Trust Stamp are protected by Patent, Trademark and Copyright.` }
              <br />
              <PrivacyPolicy /><span>|</span>
              <Link
                className={ this.cn`__footer-link` }
                to={ ROUTES.CCPA_FORM }
              >
                California Residents CCPA Request
              </Link>
            </p>
            <ul>
              { socialData.map(renderSocials) }
            </ul>
          </div>
        </div>
        { /* noscript for linkedIn insights tracking */ }
        <noscript>
          <img height="1" width="1" className={ this.cn`__hidden` } alt="" src="https://px.ads.linkedin.com/collect/?pid=1156714&fmt=gif" />
        </noscript>
      </footer>
    );
  }
}
