import atom from 'atom-js';
import throttle from 'lodash/throttle';
import webcam from 'mighty-webcamjs';
import is from 'next-is';

import { plainGet } from 'sf/helpers/request';
import { addEventListener, getWindowHeight, getWindowWidth } from 'sf/helpers/domHelper';

const queries = {
  $xxsm_breakpoint: 460,
  $xsm_breakpoint: 768,
  $sm_breakpoint: 880,
  $md_breakpoint: 1000,
  $lg_breakpoint: 1200,
  $xlg_breakpoint: 1480,
  $xxlg_breakpoint: 1680,
};

// TODO: unify device.js and _media-queries.scss breakpoints:
// 1. get rid of breakpoint (`${size}` format) reference in the JS code
// 2. change `css${size}` format to `${size}`
const deviceHelper = {
  /**
   * Check if window width resolves selector from _media-queries.scss
   */
  xxsmDown: function () { return getWindowWidth() <= queries.$xxsm_breakpoint - 1; },
  xsmDown: function () { return getWindowWidth() <= queries.$xsm_breakpoint - 1; },
  smDown: function () { return getWindowWidth() <= queries.$sm_breakpoint - 1; },
  mdDown: function () { return getWindowWidth() <= queries.$md_breakpoint - 1; },
  lgDown: function () { return getWindowWidth() <= queries.$lg_breakpoint - 1; },
  xlgDown: function () { return getWindowWidth() <= queries.$xlg_breakpoint - 1; },
  xxlgDown: function () { return getWindowWidth() <= queries.$xxlg_breakpoint - 1; },

  xxsmUp: function () { return getWindowWidth() >= queries.$xxsm_breakpoint - 1; },
  xsmUp: function () { return getWindowWidth() >= queries.$xsm_breakpoint - 1; },
  smUp: function () { return getWindowWidth() >= queries.$sm_breakpoint - 1; },
  mdUp: function () { return getWindowWidth() >= queries.$md_breakpoint - 1; },
  lgUp: function () { return getWindowWidth() >= queries.$lg_breakpoint - 1; },
  xlgUp: function () { return getWindowWidth() >= queries.$xlg_breakpoint - 1; },
  xxlgUp: function () { return getWindowWidth() >= queries.$xxlg_breakpoint - 1; },

  portrait: function () { return getWindowHeight() >= getWindowWidth(); },
  landscape: function () { return getWindowHeight() < getWindowWidth(); },

  appSupported: () => {
    return global.REALTOR ||
      !global.REALTOR &&
      (is.userMediaSupported() || webcam.helpers.detectFlash() || is.desktop() && is.safari());
  },
  iOSWebMode: () => {
    return is.iOS9() || is.iOS10();
  },
  iOSFileFallbackCameraMode: () => {
    return !global.REALTOR && (is.iOS() && !is.userMediaSupported());
  },
  isCordova: () => {
    const ua = global.navigator && global.navigator.userAgent || '';
    return /Cordova\/NaeaApp/.test(ua);
  },
};

if (!is.browser()) {
  const sayNO = function () { return false; };
  Object.keys(deviceHelper).forEach((helperName) => {
    deviceHelper[helperName] = sayNO;
  });
}

function getDeviceState() {
  const result = {};
  Object.keys(deviceHelper).forEach((helperName) => {
    result[helperName] = deviceHelper[helperName]();
  });
  return result;
}

function getDeviceScroll() {
  const doc = document.documentElement;
  return {
    leftScrollOffset: (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0),
    topScrollOffset: (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0),
  };
}

const model = atom(getDeviceState());

if (is.browser()) {
  addEventListener(window, 'resize', () => {
    model.set(getDeviceState());
  });

  addEventListener(window, 'scroll', throttle(() => {
    model.set(getDeviceScroll());
  }, 100));

  if (global.REALTOR) {
    let systemNetworkStatus = true;
    let lastCheck = 0;
    let xhr;
    let connectionCheckerInterval;
    const resetChecker = () => {
      clearInterval(connectionCheckerInterval);
      if (xhr) xhr.abort();
      lastCheck = 0;
    };
    const checkConnection = () => {
      // This is an additional check to system network status checker.
      // It might be important in case of extremally slow connection
      // or in case 3g data limit is reached - 3g connection is set so online was
      // triggered but no real data can be sent.
      if (systemNetworkStatus && lastCheck > Date.now() - 90000) {
        // if online, don't check more often than once per 90 seconds
        return;
      }
      lastCheck = Date.now();
      // use jquery ajax to prevent showing error msgs
      const req = plainGet(`/connection-checker?_=${lastCheck}`);
      xhr = req.rawRequest;
      const timeout = setTimeout(() => {
        xhr.abort();
      }, 14000);

      req
        .then(() => {
          clearTimeout(timeout);
          model.set('onLine', true);
        })
        .catch((statusObj) => {
          clearTimeout(timeout);
          if (statusObj.statusText !== 'abort') {
            model.set('onLine', false);
          }
        });
    };

    document.addEventListener('offline', () => {
      systemNetworkStatus = false;
      model.set('onLine', systemNetworkStatus);
      // disable network checker until online event is called again.
      resetChecker();
    }, false);

    document.addEventListener('online', () => {
      systemNetworkStatus = true;
      resetChecker();
      connectionCheckerInterval = setInterval(checkConnection, 15000);
      // NOTE: model.onLine is not set yet. wait for checkConnection callback.
      checkConnection();
    }, false);

    connectionCheckerInterval = setInterval(checkConnection, 15000);
    checkConnection();
  }
}

Object.assign(model, deviceHelper, {
  getBreakpoint(breakPointName) {
    return queries[`$${breakPointName}_breakpoint`];
  },
});

export default model;
